



























import { Component, Prop, Ref } from 'vue-property-decorator'
import vuescroll from 'vuescroll'

// components
import MasterCalendarLegendItem from '@/views/master/calendar/MasterCalendarLegendItem.vue'
// mixins
import MasterGroupMixin from '@/mixins/MasterGroupMixin'
// store
import { CalendarEntityType } from '@/store/types'
@Component({
  components: {
    MasterCalendarLegendItem,
  },
})
export default class PseudoLegendModal extends MasterGroupMixin {
  @Ref() scrollModal!: vuescroll

  @Prop({ required: true })
  private form!: Record<number, CalendarEntityType[]>

  private scrollOptions = {
    scrollPanel: {
      scrollingX: false,
    },
  }
}
