



































import { Component, Prop } from 'vue-property-decorator'

// components
import Tag from '@/components/_uikit/Tag.vue'
// mixins
import MasterGroupMixin from '@/mixins/MasterGroupMixin'
// store
import { CalendarEntityType, EducationMasterGroupResource } from '@/store/types'
// utils
import { subjectsColor, subjectsListOnID } from '@/utils/constants'

@Component({
  components: {
    Tag,
  },
})
export default class MasterCalendarLegendItem extends MasterGroupMixin {
  @Prop({ required: true })
  private form!: Record<number, CalendarEntityType[]>

  @Prop({ required: true })
  private group!: EducationMasterGroupResource

  private innerValue: CalendarEntityType[] = []

  private mounted() {
    this.innerValue = this.form[this.group.id] ?? []
    this.$bus.$on('resetMasterCalendarFilters', this.resetFilters)
  }

  private destroyed() {
    this.$bus.$off('resetMasterCalendarFilters', this.resetFilters as any)
  }

  private getSubjectColor(subjectID: number) {
    return subjectsColor[subjectsListOnID[subjectID]]
  }

  private resetFilters() {
    this.innerValue = []
    this.$emit('change', [], this.group.id)
  }
}
