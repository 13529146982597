


























































































import { MetaInfo } from 'vue-meta'
import { Component, Mixins, Ref, Vue } from 'vue-property-decorator'
import { sortBy } from 'lodash'
import shave from 'shave'

// components
import ButtonTextIcon from '@/components/_uikit/buttons/ButtonTextIcon.vue'
import MasterCalendarLegend from '@/views/master/calendar/MasterCalendarLegend.vue'
import Parameters from '@/components/_uikit/Parameters.vue'
import PseudoLegendModal from '@/components/PseudoLegendModal.vue'
// mixins
import MasterGroupMixin from '@/mixins/MasterGroupMixin'
import CalendarMixin from '@/mixins/CalendarMixin'
// types
import {
  CalendarEntityType, CalendarFilter,
  CalendarPageExerciseResource,
  CalendarPageLessonResource,
  CalendarPageMaterialResource, EducationMasterGroupResource,
  MasterEducationCalendarGetParams,
  NameValueResource,
} from '@/store/types'
// store
import AuthModule from '@/store/modules/auth'
import DictionaryModule from '@/store/modules/dictionary'
import MasterCalendarModule from '@/store/modules/master/calendar'
import MasterTutorialsModule from '@/store/modules/master/tutorials'
// utils
import { formatDate, formatDateRange } from '@/utils/functions'

// Note: переиспользуемая часть компонента вынесена в миксин
@Component({
  components: {
    ButtonTextIcon,
    MasterCalendarLegend,
    Parameters,
    PseudoLegendModal,
  },
})
export default class Calendar extends Mixins(MasterGroupMixin, CalendarMixin) {
  @Ref()
  private calendarRef!: Vue

  // issue with TS and Vuetify Calendar
  // https://gist.github.com/Nicklas2751/da1fbe81878df7bb531fa4978c0ce7a8
  private get calendarInstance (): Vue & { getStartOfWeek: (format: any) => any, getEndOfWeek: (format: any) => any, scrollToTime: (time: number | string | { hour: number, minute: number }) => boolean } {
    return this.$refs.calendarRef as Vue & { getStartOfWeek: (format: any) => any, getEndOfWeek: (format: any) => any, scrollToTime: (time: number | string | { hour: number, minute: number }) => boolean }
  }

  private get title(): string {
    if (this.chosenType === 'day') {
      return formatDate(this.calendarFilter.startAt)
    }
    if (this.chosenType === 'week') {
      return formatDateRange(this.calendarFilter.startAt, this.calendarFilter.endAt)
    }
    return (this.$refs.calendarRef as any).title
  }
  private get calendarTutorialActive() {
    return MasterTutorialsModule.calendarTutorialActive
  }

  private exercises: CalendarPageExerciseResource[] = []
  private lessons: CalendarPageLessonResource[] = []
  private usefulMaterial: CalendarPageMaterialResource[] = []

  // Флаг, определяющий были ли получены фильтры пользователя
  // нужен, чтобы не запрашивать события без фильтров при инициализации календаря
  private filtersLoaded = false

  private form: Record<number, CalendarEntityType[]> = {}

  private visibleCalendarLegend = false
  private visibleLegendTourModal = false
  private calendarFilter: MasterEducationCalendarGetParams = {
    endAt: '',
    filters: [],
    startAt: '',
  }

  private mounted() {
    DictionaryModule.fetchConstants()
      .catch(this.notifyError)

    MasterCalendarModule.fetchMasterCalendarFilters()
      .then(filters => {
        this.filtersLoaded = true
        if (!filters.filters.length) {
          if (this.currentMasterGroupID !== -1) {
            this.form[this.currentMasterGroupID] = [CalendarEntityType.LESSON, CalendarEntityType.EXERCISE, CalendarEntityType.USEFUL_MATERIAL]
            this.calendarFilter.filters.push({
              masterGroupId: this.currentMasterGroupID,
              entities: [CalendarEntityType.EXERCISE, CalendarEntityType.LESSON, CalendarEntityType.USEFUL_MATERIAL],
            })
          }
        } else {
          filters.filters.forEach(item => {
            this.form[item.masterGroupId] = item.entities
          })
        }
        this.fetchCalendarData()
      })

    this.$bus.$on('updateFormLegend', this.updateFormLegend)
    this.$bus.$on('showCalendarLegend', this.showCalendarLegend)
  }

  private destroyed() {
    this.$bus.$off('updateFormLegend', this.updateFormLegend as any)
    this.$bus.$off('showCalendarLegend', this.showCalendarLegend as any)
  }

  // Вызывается, когда мастер-группы пользователя обновляются по сокетам
  // нужно для того, чтобы в форме убирать группы, которых нет у пользователя
  private updateFormLegend(masterGroups: EducationMasterGroupResource[]) {
    Object.keys(this.form).forEach((item: string) => {
      if (masterGroups.findIndex(group => group.id === +item) === -1) {
        delete this.form[+item]
      }
    })
  }

  // Вызывается из туториала по календарю, с перехода на 3 шаг или с 3 шага в обратную сторону
  // а также по закрытию туториала календаря
  private showCalendarLegend(isShow: boolean) {
    this.visibleLegendTourModal = isShow
  }

  private handleChange(event: any) {
    this.calendarFilter = {
      ...this.calendarFilter,
      endAt: this.chosenType !== 'day' ? this.calendarInstance.getEndOfWeek(event.end).date : event.start.date,
      startAt: this.chosenType !== 'day' ? this.calendarInstance.getStartOfWeek(event.start).date : event.end.date,
    }
    if (this.filtersLoaded)
      this.fetchCalendarData()
  }

  private get chosenType() {
    if (this.$vuetify.breakpoint.name === 'xs' && this.currentChosenOption.value === 'week') {
      this.handleSwitchScheduleType('day')
      return 'day'
    } else if (this.currentChosenOption.value === 'day' && this.$vuetify.breakpoint.width > 451) {
      this.handleSwitchScheduleType('week')
      return 'week'
    } else {
      return this.currentChosenOption.value
    }
  }

  private fetchCalendarData() {
    if (this.currentMasterGroupID !== -1)
      this.fetchMasterFullCalendar(this.calendarFilter)
        .then(response => {
          this.lessons = response.lesson ? response.lesson : []
          this.exercises = response.exercise ? response.exercise : []
          this.usefulMaterial = response.usefulMaterial ? response.usefulMaterial : []

          const events = [...this.lessons, ...this.exercises, ...this.usefulMaterial]

          this.$nextTick(() => {
            events.forEach((item) => {
              shave(`#event-text-${item.type}-${item.id}`, 36, { spaces: false })
            })
          })

          if (this.chosenType === 'day' && [...this.exercises, ...this.lessons].length > 0) {
            this.calendarInstance.scrollToTime(formatDate(sortBy([...this.exercises, ...this.lessons], ['spendingAt'])[0].spendingAt, 'HH:mm', this.isLocalTimezone))
          }
          if (AuthModule.self && !AuthModule.self.calendarTutorialPassed)
            this.startCalendarTour()
        })
        .catch(this.notifyError)
  }

  private get events() {
    return this.largeFullCalendarEvents // mixin
  }

  private handleSwitchScheduleType (action: string) {
    this.currentChosenOption = this.menu.find((item: NameValueResource) => item.value === action) || this.menu[0]
  }

  private saveFilters() {
    const filters: CalendarFilter[] = []
    Object.keys(this.form).forEach((masterGroupId: string) => {
      if (this.form[masterGroupId as unknown as number].length)
        filters.push({
          entities: this.form[masterGroupId as unknown as number],
          masterGroupId: masterGroupId as unknown as number,
        })
    })
    this.calendarFilter.filters = []
    MasterCalendarModule.saveCalendarFilters({ filters })
      .then(() => {
        this.fetchCalendarData()
      })
      .catch(this.notifyError)
  }

  private startCalendarTour() {
    this.$bus.$emit('startCalendarTour')
  }

  private metaInfo (): MetaInfo {
    return {
      title: 'Календарь',
    }
  }
}
