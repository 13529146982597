





























import { Component, Prop } from 'vue-property-decorator'

// components
import Dialog from '@/components/modals/Dialog.vue'
import MasterCalendarLegendItem from '@/views/master/calendar/MasterCalendarLegendItem.vue'
import ModalWrapper from '@/components/modals/ModalWrapper.vue'
// mixins
import MasterGroupMixin from '@/mixins/MasterGroupMixin'
// store
import { CalendarEntityType } from '@/store/types'

@Component({
  components: {
    Dialog,
    MasterCalendarLegendItem,
    ModalWrapper,
  },
})
export default class MasterCalendarLegend extends MasterGroupMixin {
  @Prop({ default: false })
  private visible!: boolean

  @Prop({ required: true })
  private form!: Record<number, CalendarEntityType[]>

  private handleClose() {
    this.$emit('update:visible', false)
  }

  private resetFilters() {
    this.$bus.$emit('resetMasterCalendarFilters')
  }

  private saveCalendarFilters() {
    if (!(Object.keys(this.form).reduce((acc, cur) => this.form[cur as unknown as number].length + acc, 0))) {
      this.notifyError('Выберите фильтры')
    } else {
      this.$emit('saveFilters')
      this.handleClose()
    }
  }

  private handleChange(value: CalendarEntityType[], id: number) {
    this.form[id] = value
  }
}
